// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Gatsby from "gatsby";

function Bio(Props) {
  var data = Gatsby.useStaticQuery((Gatsby.graphql`
      {
        site {
          siteMetadata {
            author
          }
        }
      }
    `));
  var match = data.site.siteMetadata;
  return React.createElement("div", undefined, "I'm ", React.createElement("strong", undefined, match.author), ". I do software and running and other stuff too.");
}

var make = Bio;

var $$default = Bio;

export {
  make ,
  $$default ,
  $$default as default,
  
}
/* react Not a pure module */
